<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="false"/>
        <v-progress-linear
          v-if="carregando"
          indeterminate
          color="#70445E"
        ></v-progress-linear>
        <v-container>
          <span class="text-h4 text-center" style="color: #70445E;">Acesso ao Blog</span>
          <v-row class="justify-center align-center mt-10">
            <v-col cols="8">
              <v-text-field
                v-model="acesso.user"
                label="Login"
                placeholder="Login"
                color="#70445E"
              ></v-text-field>
              <v-text-field
                v-model="acesso.pass"
                label="Senha"
                type="password"
                placeholder="Senha"
                color="#70445E"
              ></v-text-field>
              <v-btn
                block
                color="#70445E" 
                class="white--text"
                @click="acessar()"
              >Entrar</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="false"/>
        <v-progress-linear
          v-if="carregando"
          indeterminate
          color="#70445E"
        ></v-progress-linear>
        <div class="container" id="topoBlog" style="margin-bottom: 20vh;">
          <span class="text-h4" style="color: #70445E;">Acesso ao Blog</span>
          <v-row class="justify-center align-center mt-10">
            <v-col cols="8">
              <v-text-field
                v-model="acesso.user"
                label="Login"
                placeholder="Login"
                color="#70445E"
              ></v-text-field>
              <v-text-field
                v-model="acesso.pass"
                label="Senha"
                type="password"
                placeholder="Senha"
                color="#70445E"
              ></v-text-field>
              <v-btn
                block
                color="#70445E" 
                class="white--text"
                @click="acessar()"
              >Entrar</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
// import $ from 'jquery'
import axios from 'axios'
import AppBar from '../components/AppBar.vue'
export default {
  name: 'AcessoBlog',
  components: {
    AppBar
  },
  data () {
    return {
      carregando: false,
      acesso: {
        user: '',
        pass: ''
      }
    }
  },
  methods: {
    acessar () {
      var formData = null;
      this.carregando = true;
      'use strict'
      formData = new FormData();
      formData.append("User", this.acesso.user);
      formData.append("Pass", this.acesso.pass);
      axios.post('https://laubeapi.azurewebsites.net/api/Usuarios/Acesso', formData, {
      // axios.post('https://localhost:44303/api/Usuarios/Acesso', formData, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            "Access-Control-Allow-Origin": "*"
          }
        })
        .then(() => {
          localStorage.setItem("logged", true)
          this.carregando = false;
          window.location.href = "./restrito-blog";
        })
        .catch((e) => {
          this.carregando = false;
          localStorage.removeItem("logged")
          alert('Usuario/Senha inválidos.')
          console.log(e);
        })
    },
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .linkGaleria {
      display: flex;
      flex-direction:row-reverse;
      text-decoration: none;
      color:#70445E;
    }
</style>
